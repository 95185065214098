import { defineComponent, onMounted, ref } from 'vue';
export default defineComponent({
    name: 'CTopModal',
    setup: function () {
        var isShow = ref(false);
        // const setCookie = (cname: string, cvalue: string|boolean, exdays: number) => {
        //   const d = new Date()
        //   d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
        //   const expires = 'expires=' + d.toUTCString()
        //   document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
        // }
        // const getCookie = (cname: string) => {
        //   const name = cname + '='
        //   const decodedCookie = decodeURIComponent(document.cookie)
        //   const ca = decodedCookie.split(';')
        //   for (let i = 0; i < ca.length; i++) {
        //     let c = ca[i]
        //     while (c.charAt(0) === ' ') {
        //       c = c.substring(1)
        //     }
        //     if (c.indexOf(name) === 0) {
        //       return c.substring(name.length, c.length)
        //     }
        //   }
        //   return ''
        // }
        onMounted(function () {
            isShow.value = true;
            // const isCookie = getCookie('CT')
            // if (!isCookie) {
            //   isShow.value = true
            // }
        });
        var closeModal = function () {
            // setCookie('CT', true, 365)
            isShow.value = false;
        };
        return { isShow: isShow, closeModal: closeModal };
    }
});
