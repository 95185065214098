import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1d18eb74&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=1d18eb74&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d18eb74",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopKvStaticImg: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/kv/static-img.vue').default,TopInformation: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/information/index.vue').default,TopAbout: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/about.vue').default,TopContact: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/contact.vue').default,TopCarousel: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/carousel.vue').default,TopSns: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/sns/index.vue').default,TopModal: require('/_work/runner/ballparkfantasia.com-web/ballparkfantasia.com-web/frontend/components/top/modal.vue').default})
