import { defineComponent, onMounted, ref } from 'vue';
import { useCarousel } from '@/composables/use-carousel';
export default defineComponent({
    setup: function () {
        var carouselLength = 5;
        var carouselRTL = ref();
        var carouselLTR = ref();
        onMounted(function () {
            var elementRTL = carouselRTL.value;
            var elementLTR = carouselLTR.value;
            var slideRTL = useCarousel({ element: elementRTL, reverse: false });
            slideRTL.init();
            var slideLTR = useCarousel({ element: elementLTR, reverse: true });
            slideLTR.init();
        });
        return {
            carouselLength: carouselLength,
            carouselRTL: carouselRTL,
            carouselLTR: carouselLTR
        };
    }
});
