import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
export default defineComponent({
    name: 'CModal',
    setup: function () {
        var pageScrollY = ref(0);
        var blockScroll = function () {
            var html = document.body;
            pageScrollY.value = window.scrollY;
            html.style.overflow = 'hidden';
            html.style.position = 'fixed';
            html.style.top = "-" + pageScrollY.value + "px";
            html.style.width = '100%';
            html.style.height = window.innerHeight + 'px';
        };
        var enableScroll = function () {
            var html = document.body;
            html.style.removeProperty('overflow');
            html.style.removeProperty('position');
            html.style.removeProperty('top');
            html.style.removeProperty('width');
            html.style.removeProperty('height');
            window.scrollTo(0, pageScrollY.value);
        };
        onMounted(function () {
            blockScroll();
        });
        onUnmounted(function () {
            enableScroll();
        });
    }
});
