import { debounce } from '~/plugins/helper';
export function useCarousel(options) {
    var container = options.element.getElementsByClassName('carousel__container')[0];
    var items = options.element.getElementsByClassName('carousel__item');
    var completeImgs = [];
    var fps = 30;
    var loadImage = function (container) {
        return new Promise(function (resolve) {
            var imgs = Array.from(container.querySelectorAll('.js-lazy'));
            var src = '';
            for (var i = 0; i < imgs.length; i++) {
                src = imgs[i].getAttribute('data-src');
                if (src.includes('gif')) {
                    src += '?' + new Date().getTime();
                    // no chache
                }
                imgs[i].src = src;
                // @ts-ignore
                imgs[i].parentNode.style.animationDelay = (i * 200) + 100 + 'ms';
                imgs[i].addEventListener('load', complete, false);
            }
            function complete(e) {
                var imageElement = e.currentTarget;
                completeImgs.push(imageElement);
                imageElement.removeEventListener('load', complete, false);
                var height = imageElement.clientHeight;
                resolve(height);
            }
        });
    };
    var resize = function () {
        var height = completeImgs[0].clientHeight;
        container.style.height = height + 'px';
    };
    var init = function () {
        loadImage(container).then(function (height) {
            container.style.height = height + 'px';
            var loopFunc = options.reverse ? slideLTR : slideRTL;
            loopFunc();
            window.addEventListener('resize', debounce(resize, 30));
        });
    };
    var slideRTL = function () {
        var fpsInterval, now, then, elapsed;
        var startAnimating = function (fps) {
            fpsInterval = 1000 / fps;
            then = Date.now();
            animate();
        };
        var animate = function () {
            var x = 0;
            window.requestAnimationFrame(animate);
            now = Date.now();
            elapsed = now - then;
            if (elapsed > fpsInterval) {
                then = now - (elapsed % fpsInterval);
                for (var i = 1; i <= items.length; i++) {
                    var currentElement = items[i - 1];
                    if (i === 1) {
                        x = parseInt(window.getComputedStyle(currentElement).getPropertyValue('left'));
                        currentElement.style.left = (x - 1) + 'px';
                    }
                    else {
                        var prev = items[i - 2];
                        x = parseInt(window.getComputedStyle(prev).getPropertyValue('left')) + prev.clientWidth;
                        currentElement.style.left = x + 'px';
                    }
                    if (x < -(currentElement.clientWidth)) {
                        container.insertBefore(currentElement, items[items.length - 1].nextSibling);
                    }
                }
            }
        };
        startAnimating(fps);
    };
    var slideLTR = function () {
        var prevW = 0;
        var fpsInterval, now, then, elapsed;
        var startAnimating = function (fps) {
            fpsInterval = 1000 / fps;
            then = Date.now();
            animate();
        };
        var calculateWindowWidth = function () {
            // change window width
            if (prevW !== window.innerWidth) {
                for (var i = 0; i < items.length; i++) {
                    var x = 0;
                    var item = items[i];
                    for (var j = items.length - 1; j > i; j--) {
                        x += item.clientWidth;
                    }
                    item.style.left = x + 'px';
                }
            }
            prevW = window.innerWidth;
        };
        var animate = function () {
            var x = 0;
            var ww = document.body.clientWidth;
            window.requestAnimationFrame(animate);
            now = Date.now();
            elapsed = now - then;
            if (elapsed > fpsInterval) {
                then = now - (elapsed % fpsInterval);
                for (var i = 0; i < items.length; i++) {
                    x = parseInt(window.getComputedStyle(items[i]).getPropertyValue('left')) + 1;
                    var item = items[i];
                    if (x > ww) {
                        // Last HTMLCollection element
                        var last = items[items.length - 1];
                        item.style.left = parseInt(window.getComputedStyle(last).getPropertyValue('left')) - items[i].clientWidth + 'px';
                        container.insertBefore(items[i], items[items.length - 1].nextSibling);
                    }
                    else {
                        item.style.left = x + 'px';
                    }
                }
            }
        };
        calculateWindowWidth();
        startAnimating(fps);
        window.addEventListener('resize', debounce(calculateWindowWidth, 30), false);
    };
    return {
        init: init
    };
}
